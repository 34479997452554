/* You can add global styles to this file, and also import other style files */

body {
    height: 100%;
    margin: 0;
    background-color: #f3f3f3ff; 
}

img {
    margin: 0;
    padding: 0;
}
 //<--- GLOBALES --->//
.container {
        position: relative;
        min-height: 100vh;
}
 //<--- BRAND COLORS -->//
